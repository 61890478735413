<template lang="pug">
  header
    .logo Logo
    Logout
</template>

<script>
import Logout from '@/components/auth/LogoutButton.vue';

export default {
  name: 'Header',
  components: { Logout },
};
</script>

<style scoped lang="stylus">
header
  height 75px
  padding-top 30px
  display flex
  justify-content space-between
  position: sticky
  top 0
  z-index 999
</style>
